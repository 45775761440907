import { showErrorMessage } from 'components/Helpers/Toasts';
import { mapCartItem, useCurrentCart } from 'connectors/cart/Cart';
import { AddToCartMutationApi, useAddToCartMutationApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useLatest } from 'hooks/ui/useLatest';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCallback } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm';
import { onChangeCartItemGtmEventHandler } from 'utils/Gtm/EventHandlers';
import { useGtmCartEventInfo } from 'utils/Gtm/Gtm';

type AddToCartAction = (
    productUuid: string,
    listIndex: number | null,
    quantity: number,
    gtmListName: GtmListNameType,
    isAbsoluteQuantity?: boolean,
) => Promise<AddToCartMutationApi['AddToCart'] | null>;

export const useAddToCart = (origin: GtmMessageOriginType): [AddToCartAction, boolean] => {
    const [{ fetching }, addToCart] = useAddToCartMutationApi();
    const { cartUuid, defaultStoreUuid } = usePersistStore((s) => s);
    const { currencyCode, url } = useDomainConfig();
    const t = useTypedTranslationFunction();
    const { cart } = useCurrentCart();
    const gtmCartEventInfo = useGtmCartEventInfo();
    const gtmCart = useLatest(gtmCartEventInfo.cart);
    const { updateCartUuidState } = usePersistStore((s) => s);

    const addToCartAction = useCallback<AddToCartAction>(
        async (productUuid, listIndex, quantity, gtmListName, isAbsoluteQuantity = false) => {
            const itemToBeAdded = cart?.items.find((item) => item.product.uuid === productUuid);
            const initialQuantity = itemToBeAdded?.quantity ?? 0;
            const addToCartActionResult = await addToCart({
                input: { cartUuid, productUuid, quantity, isAbsoluteQuantity, productCatnum: null },
                defaultStoreUuid,
                selectedStoreUuid: null,
            });
            updateCartUuidState(addToCartActionResult.data?.AddToCart.cart.uuid ?? null);

            // EXTEND ADDING TO CART HERE
            if (addToCartActionResult.error !== undefined) {
                showErrorMessage(t('Unable to add product to cart'), origin);

                return null;
            }

            const addToCartResult = addToCartActionResult.data?.AddToCart;

            if (addToCartResult === undefined) {
                return null;
            }

            const addedCartItem = addToCartResult.addProductResult.cartItem;
            const isProductGroupMixed = addToCartResult.addProductResult.isProductGroupMixed;

            if (isProductGroupMixed === true) {
                showErrorMessage(t('It is not possible to combine a gift voucher with regular goods.'), origin);
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (addedCartItem === null) {
                return addToCartResult;
            }

            const notOnStockQuantity = addToCartResult.addProductResult.notOnStockQuantity;

            if (notOnStockQuantity > 0) {
                showErrorMessage(
                    t(
                        'You have the maximum available amount in your cart, you cannot add more (total {{ quantity }} {{ unitName }})',
                        {
                            quantity: addedCartItem.quantity,
                            unitName: addedCartItem.product.unit.name,
                        },
                    ),
                    origin,
                );
            }

            const quantityDifference = isAbsoluteQuantity
                ? addToCartResult.addProductResult.addedQuantity - initialQuantity
                : addToCartResult.addProductResult.addedQuantity;
            const absoluteEventValue =
                Number.parseFloat(addedCartItem.product.price.priceWithoutVat) * Math.abs(quantityDifference);
            const absoluteEventValueWithTax =
                Number.parseFloat(addedCartItem.product.price.priceWithVat) * Math.abs(quantityDifference);

            setTimeout(() => {
                onChangeCartItemGtmEventHandler(
                    gtmCart.current,
                    mapCartItem(addedCartItem, currencyCode),
                    currencyCode,
                    absoluteEventValue,
                    absoluteEventValueWithTax,
                    listIndex,
                    quantityDifference,
                    gtmListName,
                    url,
                );
            }, 0);

            return addToCartResult;
        },
        [
            cart?.items,
            addToCart,
            cartUuid,
            defaultStoreUuid,
            updateCartUuidState,
            gtmCart,
            currencyCode,
            url,
            t,
            origin,
        ],
    );

    return [addToCartAction, fetching];
};

import { ButtonHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

export type ButtonDefaultPropType = {
    size?: 'bigger' | 'small' | 'smallNarrow' | 'extraSmall';
    variant?: 'primary' | 'secondary' | 'link';
    styleType?: 'light';
};

type NativeProps = ExtractNativePropsFromDefault<
    ButtonHTMLAttributes<HTMLButtonElement>,
    never,
    'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'style' | 'name' | 'className' | 'role' | 'type'
>;

type ButtonProps = NativeProps &
    ButtonDefaultPropType & {
        isDisabled?: boolean;
        hasDisabledLook?: boolean;
        isLink?: boolean;
    };

const Button: FC<ButtonProps> = ({
    isDisabled,
    isLink,
    hasDisabledLook,
    type = 'button',
    children,
    styleType,
    size = 'default',
    variant = 'default',
    className,
    ...props
}) => {
    const formProviderMethods = useFormContext();
    let buttonClassName = '';

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isClickable = isDisabled || (type === 'submit' && formProviderMethods?.formState.isSubmitting);

    if (isLink === true) {
        buttonClassName = twJoin(
            'cursor-pointer outline-0 border-0 group p-0 min-h-0 bg-none color-black',
            (isDisabled || hasDisabledLook) && 'cursor-no-drop pointer-events-none opacity-50',
            'hover:bg-none hover:text-primary hover:underline',
        );
    } else {
        buttonClassName = twJoin(
            'cursor-pointer outline-0 border-0 group inline-flex w-auto justify-center items-center text-center no-underline transition-colors ease-defaultTransition duration-200 !leading-5 rounded-md active:scale-95',
            styleType === 'light' ? 'font-light' : 'font-medium',
            (isDisabled || hasDisabledLook) && 'cursor-no-drop shadow-none',
            isClickable && 'pointer-events-none',
            size === 'default' &&
                'min-h-[50px] py-[13px] px-9 lg:text-h5 text-h6 [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
            size === 'bigger' &&
                'min-h-[50px] py-[13px] px-9 text-h6 [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
            size === 'small' &&
                'min-h-[40px] py-[9px] px-8 text-body [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
            size === 'smallNarrow' &&
                'min-h-[40px] py-[9px] px-3 text-body [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
            size === 'extraSmall' &&
                'min-h-[40px] py-[9px] px-3 text-small [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
            variant === 'default' &&
                twJoin(
                    'bg-primary text-white hover:text-white [&>svg]:hover:text-white hover:bg-[#3b3b3b]',
                    (isDisabled || hasDisabledLook) && 'bg-border [&>svg]:text-white',
                ),
            variant === 'primary' &&
                twJoin(
                    'bg-green text-white hover:text-white [&>svg]:hover:text-white hover:bg-[#004d3a]',
                    (isDisabled || hasDisabledLook) && 'bg-[#e5e5e5] [&>svg]:text-[#888888] text-[#888888]',
                ),
            variant === 'secondary' &&
                twJoin(
                    'bg-white text-primary hover:bg-white hover:text-primary hover:[&>svg]:text-primary shadow-grayShadow hover:shadow-none disabled:bg-[#e5e5e5] disabled:[&>svg]:text-[#888888]',
                    (isDisabled || hasDisabledLook) && 'bg-[#e5e5e5] [&>svg]:text-[#888888] text-[#888888]',
                ),
            variant === 'link' &&
                twJoin(
                    'bg-transparent text-primary underline decoration-border decoration-1 underline-offset-2 hover:bg-transparent hover:text-primary hover:[&>svg]:text-primary hover:decoration-primary disabled:bg-transparent disabled:[&>svg]:text-grayDarker',
                    (isDisabled || hasDisabledLook) && 'text-grayDarker [&>svg]:text-grayDarker',
                ),
        );
    }

    return (
        <button className={twMerge(buttonClassName, className)} {...props}>
            {children}
        </button>
    );
};

/* @component */
export default Button;
